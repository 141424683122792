.not-found-page {

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
        text-align: center;
        color: white;
        font-size: 30px;
        padding-bottom: 20vh;
    }
}
