.daily-video-component {
    a {
        color: #79DBD5
    }

    > .body {
        // max-width: 800px;
        width: 100%;
        margin: 0 auto;
        line-height: 24px;
        color: #444;

        .video-iframe {
            height: 45vw;
            width: 100%;

            @media (min-width: 768px) {
                width: 100%;
            }
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}
