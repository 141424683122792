.none-message-component {

    .progress {
        margin-top: 30px;
        background-color: #EEE;
        margin-bottom: 30px;

        .MuiLinearProgress-barColorPrimary {
            background-color: #77CAF1;
        }
    }

    .content {
        margin-top: 40px;
        text-align: center;
    }

}
