.recommendations-page {
    height: 100%;
    padding-bottom: 50px;
    color: white;

    > .please-wait-component {
        padding-top: 10%;
    }

}
