.benefit-component {
    max-width: 500px;
    background-color: white;
    padding: 30px 20px 20px 20px;
    text-align: center;
    box-sizing: border-box;

    @media (min-width: 800px) {
        padding: 40px 30px 30px 30px;;
    }

    img {
        width: 100%;
        max-width: 200px;
    }

    h1 {
        font-size: 18px;
    }
}
