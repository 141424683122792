.recommendations-list-component {
    max-width: 700px;
    margin: 1vh auto 0 auto;
    @media (min-width: 950px) {
      margin: 10vh auto 0 auto;
    }

    > .no-recommendations {
        max-width: 500px;
        margin: 25vh auto;
        width: 80%;
        text-align: center;

        .white-button {
            display: block;
            width: 150px;
            margin: 50px auto;
        }
    }

    > .recommendation {
        text-align: center;
        margin-bottom: 40px;

        .thumbnail {
          margin: 0 auto;
          max-width: 500px;
          width: 100%;
        }

    }

}
