.login-form-component {
    text-align: center;
    padding: 30px 15px;

    @media(min-width: 500px) {
        padding: 30px;
    }

    > h1 {
        margin-bottom: 30px;
    }

    > form {
        .field {
            display: flex;
            width: 100%;
            margin-bottom: 30px;
        }
    }
}
