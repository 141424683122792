.register-home-page {

    @media (min-width: 700px) {
        width: 700px;
        margin: 0 auto;
    }

    > .header {
        padding-bottom: 20px;
        padding-top: 40px;
    }

    > .body {
        padding-bottom: 40px;
    }
}
