.daily-video-page {
    background: white;
    min-height: 100vh;
    height: 100%;

    > .please-wait-component {
        padding-top: 10%;
        h2 {
            color: #bbbbbb;
        }

        .MuiCircularProgress-root {
            color: #bbbbbb;
        }
    }

    > .daily-video-component {
        margin: 0 auto;
        width: 100%;
        padding-bottom: 50px;
    }
}
