.marketing-page {

    @media (min-width: 700px) {
        width: 700px;
        margin: 0 auto;
    }

    > .header {
        padding: 10px;
        text-align: center;
        @media (min-width: 500px) {
            padding-top: 40px;
            text-align: unset;
        }

        .logo-component {
            margin-bottom: 5px;
            margin-top: 10px;
            @media (min-width: 500px) {
                float: left;
                margin: unset;
            }
        }

    }

    > .body {

        @media (min-width: 500px) {
            padding: 30px 0;
        }

        > .please-wait-component {
            padding-top: 15vh;
        }

        .paper-container {
            @media (min-width: 940px) {
                padding: 10px 40px;
                border-radius: 16px;
            }
        }

    }
}
