.login-page {

    @media (min-width: 500px) {
        width: 500px;
        margin: 0 auto;
    }

    > .header {
        padding-bottom: 20px;
        padding-top: 40px;
    }

}
