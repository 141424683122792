.daily-journey-component {
    a {
        color: #79DBD5
    }

    > .header {
        background-color: #fcbeae;
        color: white;
        box-sizing: border-box;
        padding: 30px;
        width: 100%;

        @media (min-width: 700px) {
            min-height: 340px;
            padding: 70px 70px 30px 70px;
        }

        > .inner {
            max-width: 800px;
            margin: 0 auto;

            > .logo {
                max-width: 100px;
            }

            > .date {
                margin-top: 26px;
                font-size: 20px;
                font-weight: bold;
                font-family: sans-serif;
            }

            > .title {
                font-family: Times, sans-serif;
                font-weight: bold;
                margin-top: 50px;
                font-size: 50px;
            }
        }

    }

    > .body {
        max-width: 800px;
        margin: 0 auto;
        line-height: 24px;
        color: #444;

        h1 {
            line-height: 48px;
        }

        > .inner {
            max-width: 600px;
            padding: 40px 30px 0 40px;
            @media (min-width: 700px) {
                padding: 40px 0 0 0;
            }
        }

        .video-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-bottom: 20px;
            @media (min-width: 700px) {
                padding: 40px 0;
            }

            img {
                height: 300px;
                width: 300px;
                border-radius: 150px;
            }

            .column {
                box-sizing: border-box;
                width: 100%;
                @media (min-width: 600px) {
                    width: 50%;
                }
            }

            .column.info {
                padding-left: 40px;
                padding-top: 40px;
            }
        }

    }

    > .divider {
        height: 5px;
        width: 90%;
        margin: 20px auto;
        max-width: 800px;
        background: rgb(126,129,213);
        background: linear-gradient(90deg, rgba(126,129,213,1) 0%, rgba(111,196,214,1) 100%);
    }
}
