.resources-page {
    background: white;
    min-height: 100vh;
    height: 100%;

    > .navbar {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #66818f;
    }


    > .please-wait-component {
        padding-top: 5%;
        h2 {
            color: #bbbbbb;
        }

        .MuiCircularProgress-root {
            color: #bbbbbb;
        }
    }

}
