.sign-up-form-component {
    
    .body-content {
        
        .field {
            width: 100%;
            margin-bottom: 30px;
        }

        .email-notice {
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
            margin-top: 5px;
            display: inline-block;
            font-size: 12px;
            font-family: Montserrat, serif !important;
            color: #66818f;
        }
    }

    .error-content {
        padding: 20px 0;
        text-align: center;
        .header {

        }
        .body {
            font-size: 14px;
            margin-top: 30px;
        }
    }

    .loading-content {
        width: 100px;
        height: 100px;
        margin: 40px auto;

        .loading-spinner {
            color: #78caf1;
        }
    }

}
