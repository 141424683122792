.resources-component {
    min-height: calc(100vh - 370px);

    #heroImage {
        width: 100%;
    }

    .welcome-header {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 20px;

        h1 {
            line-height: 50px
        }
    }

    #companyLogo {
        width: 30%;
        object-fit: contain;
        @media (min-width: 600px) {
            width: 40%;
        }
    }

    #image1 {
        max-width: 280px;
        width: 100%;
        margin: 0 auto;
        @media (min-width: 600px) {
            max-width: 300px;
        }
    }

    #image2 {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        @media (min-width: 600px) {
            max-width: 600px;
        }
    }

    #image3 {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        @media (min-width: 600px) {
            max-width: 600px;
        }
    }


    #benefits-section {
        padding: 20px;

        h1 {
            text-align: center;
        }

        p {
            margin-bottom: 30px;
        }
    }

    #third-party-benefits-section {
        text-align: center;

        p {
            margin-bottom: 60px;
        }

        @media (min-width: 600px) {
            margin-bottom: 60px;
        }

        .divider {
            max-width: 1200px;
            margin: 0 auto;
            width: 100%;
            height: 1px;
            background-color: #EEE;
            margin-top: 60px;
        }

        .benefit-component {
            max-width: 600px;
            margin: 0 auto 20px auto;
        }

        .benefits-list {
            margin-bottom: 40px;
        }
    }

    #standard-benefits-section {
        .benefits-list {

            > .benefit-component {
                margin: 0 auto 20px auto;
            }
        }
    }

    #benefits-grid {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;

        .left {
            width: 45%;
            display: none;
            text-align: center;
            margin-right: 5%;
            @media (min-width: 800px){
                display: block;
                padding: 10% 0;
            }
        }

        .right {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
            @media (min-width: 800px){
                width: 50%;
            }
        }

    }

    > .info-grid {
        background-color: white;

        > .column {
            padding: 30px;
            text-align: center;
            @media(min-width: 600px) {
                text-align: left;
            }

            h1 {
                text-align: center;
            }
        }

    }


}
