.navigation-bar-component {

  &.MuiAppBar-colorPrimary {
    background-color: white;
    color: black;
  }

  &.MuiPaper-elevation4 {
    box-shadow: none;
  }

  > .toolbar {

      > .logo {
        max-width: 100px;
        margin-right: 20px;
        margin-left: 10px;
      }

    > .toggle-menu-button {
      display: block;
      @media (min-width: 950px) {
        display: none;
      }
    }

    > .full-screen-links {
      display: none;
      @media (min-width: 950px) {
        display: block;
      }

      .nav-button {
        margin-right: 15px;
      }
    }
  }
}
