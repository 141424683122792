.please-wait-component {
    > .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        > h2 {
            color: white;
            margin-bottom: 32px;
        }

        > .loading-spinner {
            color: white;
        }
    }
}
