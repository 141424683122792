.logo-component {
    margin-bottom: 18px;

    > span {
        text-align: center;
        margin: 0 auto;
        display: block;
        color: white;
    }

    > .logo {
        margin: 0 auto;
        width: 140px;
        display: block;
        font-family: Montserrat, serif !important;
    }

    .live-caption {
        font-family: Montserrat, serif !important;
        color: white;
        text-align: center;
    }

}
